export default {
    name: 'modeling',
    all_data: [
        {
            'f1': 1,
            'f2': 'Строительство пристройки на 528 мест в СОШ им. Б. Момышулы г. Павлодар',
            'f3': 'УС',
            'f4': 'г. Павлодар',
            'f5': 'Поддержан',
            'f6': 1371,
            'f7': 1371,
            'f8': '7,9',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/schools/bip-schools-modeling/'
        },
        {
            'f1': 2,
            'f2': 'Строительство пристройки на 348 мест в СОШГ №9 г. Павлодар',
            'f3': 'УС',
            'f4': 'г. Павлодар',
            'f5': 'Поддержан',
            'f6': 904,
            'f7': 451,
            'f8': '8,5',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/schools/bip-schools-modeling/'
        },
        {
            'f1': 3,
            'f2': 'Строительство пристройки на 100 мест в школе-лицей г. Аксу',
            'f3': 'УС',
            'f4': 'г. Аксу',
            'f5': 'Не поддержан',
            'f6': 259,
            'f7': 0,
            'f8': '6,1',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/schools/bip-schools-modeling/'
        },
        {
            'f1': 4,
            'f2': 'Средний ремонт автомобильной дороги Актогай-Шолаксор км 35-39 (4 км), 50-54 (4 км)',
            'f3': 'УПТиАД',
            'f4': 'Актогайский р-г',
            'f5': 'Поддержан',
            'f6': 287,
            'f7': 287,
            'f8': '7,2',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/roads/bip-roads-modeling/'
        },
        {
            'f1': 5,
            'f2': 'Средний ремонт автомобильной дороги Панфилово-Бестобе км 49-64 (15 км)',
            'f3': 'УПТиАД',
            'f4': 'Иртышский р-н',
            'f5': 'Поддержан',
            'f6': 614,
            'f7': 405,
            'f8': '8,2',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/roads/bip-roads-modeling/'
        },
        {
            'f1': 6,
            'f2': 'Средний ремонт автомобильной дороги Беловка-Калкаман км 19-33 (14 км)',
            'f3': 'УПТиАД',
            'f4': 'г. а. Аксу',
            'f5': 'Не поддержан',
            'f6': 494,
            'f7': 0,
            'f8': '6,3',
            'f9': 'На рассмотрении',
            'f10': '#/demo-budget-execution/roads/bip-roads-modeling/'
        }
    ]
};