<template>
    <b-container>
        <div class="section-title budget">Моделирование</div>
        <b-card>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="АБП"
                        label="АБП"
                    >
                        <b-form-select v-model="selected1" :options="options1"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Населенный пункт"
                        label="Населенный пункт"
                    >
                        <b-form-select v-model="selected2" :options="options2"></b-form-select>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group
                        id="fieldset-horizontalq1"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="7"
                        description="Срок действия госэкспертизы"
                        label="Срок действия госэкспертизы"
                    >
                        <b-form-select v-model="selected3" :options="options3"></b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-card>
        <b-tabs>
            <b-tab title="На рассмотрении">
                <b-table :items="modelingData.all_data" :fields="tableFields1" bordered no-border-collapse striped thead-class="bold_header">
                    <template #cell(f2)="data">
                        <div class="text-left">
                            <a :href="data.item.f10">{{ data.value }}</a>
                        </div>
                    </template>
                    <template #cell(f6)="data">
                        <div class="text-right">
                            {{ $n(data.value) }}
                        </div>
                    </template>
                    <template #cell(f7)="data">
                        <div class="text-right">
                            {{ $n(data.value) }}
                        </div>
                    </template>
                    <template #cell(f8)="data">
                        <div class="text-right">
                            {{ data.value }}
                        </div>
                    </template>
                </b-table>
            </b-tab>
            <b-tab title="Рассмотренные">
                <div class="h3 mb-0 text-right">
                    <b-icon-cart4 variant="success"></b-icon-cart4>
                    <b-badge pill variant="warning">{{ checkedRows }}</b-badge>
                </div>
                <b-table :items="modelingData.all_data" :fields="tableFields2" bordered no-border-collapse striped thead-class="bold_header">
                    <template #cell(f2)="data">
                        <div class="text-left">
                            <a :href="data.item.f10">{{ data.value }}</a>
                        </div>
                    </template>
                    <template #cell(f6)="data">
                        <div class="text-right">
                            {{ $n(data.value) }}
                        </div>
                    </template>
                    <template #cell(f7)="data">
                        <div class="text-right">
                            {{ $n(data.value) }}
                        </div>
                    </template>
                    <template #cell(f8)="data">
                        <div class="text-right">
                            {{ data.value }}
                        </div>
                    </template>
                </b-table>
            </b-tab>
        </b-tabs>
    </b-container>
</template>

<script>
import modeling from './modelling'
import {BIconCart4} from 'bootstrap-vue'

export default {
    name: 'Modelling',
    components: {
        BIconCart4
    },
    data() {
        return {
            options1: [
                {value: null, text: 'Все'},
                {value: 'УС', text: 'УС'},
                {value: 'УПТиАД', text: 'УПТиАД'},
            ],
            selected1: 'УПТиАД',
            options4: [
                {value: 'Дороги', text: 'Дороги'},
                {value: 'Здравоохранение', text: 'Здравоохранение'},
                {value: 'Образование', text: 'Образование'},
            ],
            selected4: 'Дороги',
            options2: [
                {value: null, text: 'Все'},
                {value: 'Актогайский р-н', text: 'Актогайский р-н'},
                {value: 'Баянаульский р-н', text: 'Баянаульский р-н'},
                {value: 'Железинский р-н', text: 'Железинский р-н'},
                {value: 'Иртышский р-н', text: 'Иртышский р-н'},
                {value: 'Теренкольский р-н', text: 'Теренкольский р-н'},
                {value: 'Аккулинский р-н', text: 'Аккулинский р-н'},
                {value: 'Майский р-н', text: 'Майский р-н'},
                {value: 'Павлодарский р-н', text: 'Павлодарский р-н'},
                {value: 'Успенский р-н', text: 'Успенский р-н'},
                {value: 'Щербактинский р-н', text: 'Щербактинский р-н'},
                {value: 'г. Аксу', text: 'г. Аксу'},
                {value: 'г. Павлодар', text: 'г. Павлодар'},
                {value: 'г. Экибастуз', text: 'г. Экибастуз'},
            ],
            selected2: null,
            options3: [
                {value: null, text: 'Все'},
                {value: '2021', text: '2021'},
                {value: '2022', text: '2022'},
                {value: '2023', text: '2023'},
            ],
            selected3: null,
            tableFields1: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проект'
                },
                {
                    key: 'f3',
                    label: 'АБП'
                },
                {
                    key: 'f4',
                    label: 'Нас. пункт'
                },
                {
                    key: 'f9',
                    label: 'Статус'
                },
                {
                    key: 'f6',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f7',
                    label: 'Сумма к финанс. из МБ, млн тенге'
                },
                {
                    key: 'f8',
                    label: 'Балл приоритетности'
                },
            ],
            tableFields2: [
                {
                    key: 'f1',
                    label: '№'
                },
                {
                    key: 'f2',
                    label: 'Проект'
                },
                {
                    key: 'f3',
                    label: 'АБП'
                },
                {
                    key: 'f4',
                    label: 'Нас. пункт'
                },
                {
                    key: 'f5',
                    label: 'Статус'
                },
                {
                    key: 'f6',
                    label: 'Стоимость, млн тенге'
                },
                {
                    key: 'f7',
                    label: 'Сумма к финанс. из МБ, млн тенге'
                },
                {
                    key: 'f8',
                    label: 'Балл приоритетности'
                },
            ]
        }
    },
    computed: {
        modelingData() {
            return modeling
        },
        checkedRows() {
            let sum = 0
            for (const item of this.modelingData.all_data) {
                if (item.f5 === 'Поддержан') {
                    sum +=1;
                }
            }
            return sum
        },
    }
}
</script>

<style scoped>

</style>